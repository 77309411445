.main-banner {
  width: 100%;
  height: 800px;
  background: url('/dist/images/banner-screenshot.png') no-repeat 0 0;
  background-size: cover;
  @include mobile {
    background-size: contain;
  }
  &__inner {
    float: left;
    margin-top: 200px;
    max-width: 56%;
    @include tablet {
      max-width: 100%;
    }
    @include mobile {
      max-width: 100%;
    }
    h3 {
      color: #fff;
      font-size: 54px;
      line-height: 62px;
      font-weight: 700;
      margin-bottom: 20px;
      text-transform: uppercase;
      @include mobile {
        @include rem(font-size, 34px);
      }
      span {
        color: #ff2232;
      }
    }
    p {
      color: #fff;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 20px;
    }

    .hero-btn {
      @include rem(margin-top, 100px);
      display: flex;
      .btn-owner {
        @include rem(height, 56px);
        @include rem(line-height, 56px);
        @include rem(padding, 15px 25px);
        @include rem(font-size, 24px);
        display: flex;
        font-weight: 700;
        position: relative;
        text-transform: uppercase;
        text-decoration: none;
        color: #fff;
        background: #ff2232;
        align-items: center;
        transition: all 0.5s;
        &:hover {
          &::before {
            background-position: right;
          }
        }
        @include mobile {
          @include rem(FONT-SIZE, 16px);
          line-height: normal;
        }
        &::before {
          @include rem(height, 56px);
          @include rem(line-height, 56px);
          @include rem(width, 56px);
          @include rem(right, -56px);
          transition: all 0.5s;
          top: 0;
          position: absolute;
          background-color: #fff;
          content: '';
          align-self: center;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z' fill='%23FF2232'/%3E%3C/svg%3E%0A");
        }
      }
      .tooltip {
        @include rem(margin-left, 70px);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }
  &_right {
    float: right;
    width: 42%;
    position: relative;
    display: flex;
    height: 800px;
    justify-content: flex-end;
    align-items: flex-end;
    @include tablet {
      @include rem(margin-top, 250px);
      width: 100%;
      overflow: visible;
      flex-direction: column;
      height: unset;
    }
    @include mobile {
      width: 100%;
      overflow: visible;
      flex-direction: column;
      height: unset;
    }
    span.img-holder {
      float: left;
      position: absolute;
      left: 0;
      top: 0;
      left: -170px;
      top: 115px;
      width: 640px;
      height: 567px;
      background: url('/dist/images/elf-figure.png') no-repeat 0 0;
      .mobile-img {
        display: none;
        @include mobile {
          display: flex;
          align-self: center;
        }
        @include tablet {
          display: flex;
          align-self: center;
        }
        @include tablet-big {
          display: flex;
          align-self: center;
        }
      }
      @include tablet-big {
        @include rem(left, -100px);
      }
      @include tablet {
        @include rem(margin-top, 150px);
        position: relative;
        left: unset;
        top: unset;
        width: 100%;
        height: unset;
        float: unset;
        background: unset;
        display: flex;
        justify-content: center;
      }
      @include mobile {
        @include rem(margin-top, 150px);
        position: relative;
        left: unset;
        top: unset;
        width: 100%;
        height: unset;
        float: unset;
        background: unset;
        display: flex;
        justify-content: center;
      }
    }
    .dsc-right {
      width: 100%;
      padding-bottom: 210px;
      position: relative;
      // z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      h5 {
        color: #fff;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: bold;
        text-align: right;
        line-height: normal;
        @include mobile {
          text-align: center;
          width: 100%;
        }
        @include tablet {
          text-align: center;
        }
      }
      h6 {
        @include rem(margin-top, 10px);
        color: #fff;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: bold;
        text-align: right;
        line-height: normal;
        @include mobile {
          text-align: center;
          width: 100%;
        }
        @include tablet {
          text-align: center;
        }
      }
      .bottom-img-holder {
        float: left;
        width: 392px;
        height: 84px;
        background: #00aeef;
        margin-top: 10px;
        img {
          display: block;
          margin: 0 auto;
          text-align: center;
        }
        @include mobile {
          width: 100%;
        }
      }
      @include tablet {
        align-items: center;
      }
      @include mobile {
        align-items: center;
      }
    }
  }
}
.section-block {
  float: left;
  width: 100%;
  position: relative;
  // z-index: 2;
}
.video-block {
  margin-top: -150px;
}
#my-video {
  width: 100%;
  height: 453px;
}
#my-video2 {
  width: 100%;
  height: 125px;
}
#my-video3 {
  width: 100%;
  height: 125px;
  border: 1px solid rgba(255, 255, 255, 0.26);
  border-radius: 8px;
}
#my-video2 .vjs-tech,
#my-video3 .vjs-tech {
  border: 1px solid rgba(255, 255, 255, 0.26);
  border-radius: 8px;
}
.left-side-video {
  border-top: 3px solid #ff2232;
}
.right-side-video {
  padding-top: 20px;
  height: 453px;
  background: url('/dist/images/video-bg.png') no-repeat center rgba(26, 41, 35, 0.5);
  border-top: 3px solid #ff2232;
  &__box {
    text-align: center;
    position: relative;
    h4 {
      color: #fff;
      font-size: 24px;
      font-weight: bold;
      margin-top: 10px;
      span {
        color: #ff2232;
      }
    }
    p {
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      span {
        color: #ff2232;
      }
    }
  }
  .streaming-txt {
    background: #ff2232;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    padding: 10px;
    text-transform: uppercase;
    position: absolute;
    left: calc(50% - 60px);
    top: -38px;
    z-index: 1;
  }
}
.vs-txt {
  display: block;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  font-size: 36px;
  font-weight: 400;
  color: #ff2232;
  margin: 10px 0;
  @include mobile {
    @include rem(font-size, 28px);
  }
}
.earn-slider-section {
  margin-top: 100px;
  .row {
    display: flex;
    @include mobile {
      display: block;
    }
    .flex-wrapper-items {
      display: flex;
      justify-content: center;
      flex-direction: column;
      .item {
        display: flex;
        flex-direction: column;
        h2 {
          font-size: 54px;
          color: #fff;
          font-weight: bold;
          margin-bottom: 20px;
          text-transform: uppercase;
          @include mobile {
            @include rem(font-size, 34px);
          }
        }
        p {
          color: #fff;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
  .poster-wrapper {
    height: 500px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    @include mobile {
      height: 100%;
      @include rem(margin-top, 20px);
    }
  }
}
.see-all {
  float: left;
  border: 2px solid #ff2232;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  padding: 13px 22px;
  text-align: center;
  text-decoration: none;
  position: absolute;
  top: 107px;
  right: 150px;
  z-index: 2;
  @include mobile {
    @include rem(margin-left, 20px);
    position: unset;
  }
}
#my-video {
  @include mobile {
    height: 250px;
  }
}
.my-video4-dimensions {
  @include mobile {
    @include rem(height, 250px);
  }
}

.efc-dao-dsc {
  @include mobile {
    margin-top: 0 !important;
  }
}

.section-bg-holder {
  float: left;
  display: flex;
  margin-top: 94px;
  width: 100%;
  padding: 10px;
  background-image: linear-gradient(to right, rgba(75, 71, 71, 0.3), rgba(255, 255, 255, 0));
  position: relative;
  z-index: 7;
  @include tablet {
    flex-direction: column;
    align-items: center;
  }
  @include mobile {
    flex-direction: column;
  }
  .img-thumb {
    width: 345px;
    min-width: 345px;
    height: 354px;
    @include tablet-big {
      height: unset;
    }
    @include mobile {
      width: auto;
      min-width: unset;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .description-right {
    padding-left: 26px;
    padding-top: 30px;
    @include mobile {
      padding-left: 0;
    }
    @include tablet {
      padding-left: 0;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    h3 {
      font-weight: bold;
      color: #ff2232;
      font-size: 32px;
      margin-bottom: 40px;
      text-transform: uppercase;
      @include mobile {
        @include rem(margin-bottom, 10px);
        @include rem(font-size, 24px);
        text-align: center;
      }
    }
    .select-h {
      display: flex;
      float: left;
      align-items: center;
      @include tablet {
        flex-direction: column;
      }
      .equality-wrapper {
        @include mobile {
          @include rem(margin-top, 25px);
          display: flex;
          text-align: center;
          justify-content: center;
          width: 100%;
        }
      }
      .choices[data-type*='select-one'] {
        @include rem(padding, 0 10px);
        &.is-open {
          &:after {
            border: none;
            transform: rotate(90deg);
            transition: all 0.5s;
          }
        }
        &:after {
          @include rem(right, 25px);
          @include rem(width, 18px);
          @include rem(height, 8px);
          border: none;
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml,%3Csvg width='18' height='8' viewBox='0 0 18 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.41 0.294983L12 4.87498L16.59 0.294983L18 1.70498L12 7.70498L6 1.70498L7.41 0.294983Z' fill='white'/%3E%3C/svg%3E%0A");
          transition: all 0.5s;
        }
      }
      .choices {
        margin-bottom: 0;
        &__item--selectable {
          padding-right: 0;
        }
        &__inner {
          @include rem(padding, 10px 20px);
          @include rem(font-size, 24px);
          background-color: transparent;
          color: #fff;
          text-align: center;
          border: 1px solid #ff2232;
          border-radius: 0;
        }
        &__list--dropdown {
          width: calc(100% - 20px);
          border: none;
          border-radius: none;
        }
        @include mobile {
          display: flex;
          align-items: center;
          @include rem(margin, 10px 0);
        }
        @include tablet {
          @include rem(margin, 30px 0);
        }
      }
      label {
        color: #fff;
        font-size: 36px;
        font-weight: bold;
        @include mobile {
          @include rem(font-size, 28px);
          align-self: center;
        }
      }
      span {
        color: #fff;
        font-size: 36px;
        font-weight: bold;
        @include mobile {
          @include rem(font-size, 28px);
        }
      }
      h6 {
        color: #fff;
        font-size: 36px;
        font-weight: bold;
        position: relative;
        display: inline-block;
        margin-left: 10px;
        @include mobile {
          @include rem(font-size, 28px);
          @include rem(margin-left, 0);
          text-align: center;
        }
        p {
          position: absolute;
          top: -17px;
          left: 0;
          font-size: 16px;
          font-weight: bold;
          @include mobile {
            width: 100%;
          }
          @include tablet {
            width: 100%;
          }
        }
      }
      select {
        option {
          font-size: 20px;
        }
      }
      @include mobile {
        @include rem(padding-left, 0);
        display: flex;
        flex-direction: column;
        width: 100%;
      }
    }
    .start-link {
      @include rem(margin-top, 20px);
      text-transform: uppercase;
      font-size: 14px;
      color: #fff;
      background: #ff2232;
      text-align: center;
      padding: 14px 49px;
      text-decoration: none;
      float: left;

      @include tablet-big {
        @include rem(margin-top, 15px);
        float: left;
      }
      @include tablet {
        @include rem(margin-top, 15px);
        float: left;
      }
      @include mobile {
        @include rem(margin-top, 15px);
        float: unset;
        display: inline-block;
      }
    }
    .equal-txt {
      display: flex;
      align-items: center;
      float: left;
      width: 100%;
      .tooltip {
        position: relative;
        @include rem(margin-left, 10px);
        @include mobile {
          @include rem(margin-left, 0);
          @include rem(margin-top, 20px);
        }
        svg {
          cursor: pointer;
        }
        .hover-price {
          @include rem(border-radius, 20px);
          @include rem(margin-left, 10px);
          @include rem(padding, 10px 30px);
          text-align: center;
          position: absolute;
          top: 100%;
          left: -50%;
          transform: translateX(-50%);
          background-color: #000;
          opacity: 0;
          pointer-events: none;
          transition: all 0.5s;
          p {
            @include rem(font-size, 14px);
          }
        }
        &:hover {
          .hover-price {
            pointer-events: all;
            opacity: 1;
          }
        }
      }
      @include mobile {
        text-align: center;
      }
    }
  }
}

.equal-txt {
  float: left;
  width: 100%;
  margin: 12px 0;
  @include tablet {
    justify-content: center;
  }
  @include mobile {
    flex-direction: column;
  }
  p {
    color: #fff;
    font-size: 36px;
    font-weight: bold;
    @include mobile {
      @include rem(font-size, 28px);
    }
    span {
      color: #00ff19;
    }
  }
}
.bottom-txt {
  float: left;
  width: 100%;
  text-align: left;
  @include mobile {
    text-align: center;
  }
  p {
    color: #fff;
    font-size: 16px;
    line-height: 24px;
  }
}
.video-section {
  padding-top: 200px;
  padding-bottom: 200px;
  background: url('/dist/images/icon-G.png') no-repeat top center;
  @include mobile {
    @include rem(padding-top, 130px);
    @include rem(padding-bottom, 50px);
  }
  h2 {
    margin: 44px 0;
    text-align: center;
    font-size: 54px;
    color: #fff;
    font-weight: 400;
    text-transform: uppercase;
    @include mobile {
      @include rem(font-size, 28px);
    }
  }
}
.video-section-unicef {
  z-index: 1;
  max-width: 806px;
  display: block;
  margin: 0 auto;
  margin-top: -80px;
  h4 {
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 10px;
    text-transform: uppercase;
    @include mobile {
      @include rem(font-size, 28px);
    }
  }
  #my-video4 {
    margin: 0 auto;
    display: block;
    @include mobile {
      width: 100%;
    }
    @include tablet {
      width: 100%;
    }
  }
  @include mobile {
    max-width: 100%;
  }
  @include tablet {
    max-width: 100%;
  }
}
.efc-dao-section {
  background: url('/dist/images/bg-gradient.png') no-repeat 0 0;
  background-size: cover;
  position: relative;
  padding-bottom: 130px;
  @include mobile {
    @include rem(padding-top, 20px);
  }

  .container {
    z-index: 4;
  }
  h2 {
    font-size: 54px;
    font-weight: 400;
    color: #fff;
    text-align: center;
    margin: 30px 0;
    text-transform: uppercase;
    @include mobile {
      @include rem(font-size, 28px);
      text-align: left;
    }
  }
}
.efc-dao-dsc {
  float: left;
  width: 100%;
  position: relative;

  &::after {
    float: left;
    content: '';
    position: absolute;
    left: -90px;
    bottom: 40px;
    width: 80px;
    height: 83px;
    background: url('/dist/images/efc-dao-icon.png') no-repeat bottom left;
    @include tablet {
      position: relative;
      top: unset;
      left: unset;
      @include rem(margin-top, 20px);
    }
    @include mobile {
      position: relative;
      top: unset;
      left: unset;
      @include rem(margin-top, 20px);
    }
    @include tablet-big {
      position: relative;
      top: unset;
      left: unset;
      @include rem(margin-top, 20px);
    }
  }
  p {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 40px;
    line-height: 1.3em;
  }
}
.list-items-dsc {
  float: left;
  width: 100%;
  margin-top: 50px;
  ul {
    li {
      float: left;
      width: 50%;
      color: #fff;
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      .inner-dsc {
        display: flex;
        align-items: center;
        padding-bottom: 20px;
        width: calc(100% - 20px);
        border-bottom: 1px solid rgba(255, 255, 255, 0.26);
      }
      span {
        font-size: 54px;
        color: #fff;
        font-weight: normal;
        margin-right: 20px;
      }
      p {
        font-size: 16px;
        color: #fff;
      }
      @include mobile {
        width: 100%;
      }
    }
  }
}

.elf-slider-section {
  float: left;
  width: 100%;
  margin-top: 50px;
  h5 {
    color: #fff;
    font-size: 24px;
    font-weight: normal;
    margin-bottom: 13px;
    text-transform: uppercase;
  }
  h2 {
    font-size: 54px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 20px;
    @include mobile {
      @include rem(font-size, 34px);
    }
  }
  .efc-slider {
    // width: 100vw;
    .item {
      background: rgba(98, 98, 98, 0.3);
      padding: 16px;
      padding-bottom: 74px;
      .choose-elf {
        float: right;
        color: #fff;
        padding: 14px 24px;
        text-align: center;
        background: #ff2232;
        font-size: 14px;
        font-weight: bold;
        text-decoration: none;
        margin-top: -30px;
        position: relative;
        z-index: 1;
      }
    }
    .owl-stage {
      padding-left: 0 !important;
    }

    .owl-nav {
      display: block !important;
      button {
        span {
          display: none;
        }
      }
    }
    .owl-prev {
      float: left;
      width: 54px;
      height: 54px;
      display: block;
      margin-right: 20px;
      background-image: url("data:image/svg+xml,%3Csvg width='54' height='54' viewBox='0 0 54 54' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M45 27C45 36.9225 36.9225 45 27 45C17.0775 45 9 36.9225 9 27C9 17.0775 17.0775 9 27 9C36.9225 9 45 17.0775 45 27ZM49.5 27C49.5 14.58 39.42 4.5 27 4.5C14.58 4.5 4.5 14.58 4.5 27C4.5 39.42 14.58 49.5 27 49.5C39.42 49.5 49.5 39.42 49.5 27ZM27 29.25L36 29.25L36 24.75L27 24.75L27 18L18 27L27 36L27 29.25Z' fill='white'/%3E%3C/svg%3E%0A") !important;
    }
    .owl-next {
      width: 54px;
      height: 54px;
      display: block;
      float: left;
      background-image: url("data:image/svg+xml,%3Csvg width='54' height='54' viewBox='0 0 54 54' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 27C9 17.0775 17.0775 9 27 9C36.9225 9 45 17.0775 45 27C45 36.9225 36.9225 45 27 45C17.0775 45 9 36.9225 9 27ZM4.5 27C4.5 39.42 14.58 49.5 27 49.5C39.42 49.5 49.5 39.42 49.5 27C49.5 14.58 39.42 4.5 27 4.5C14.58 4.5 4.5 14.58 4.5 27ZM27 24.75L18 24.75L18 29.25L27 29.25L27 36L36 27L27 18L27 24.75Z' fill='white'/%3E%3C/svg%3E%0A") !important;
    }

    .owl-nav {
      position: absolute;
      right: 0;
      bottom: -80px;
    }
  }
  .see-all {
    position: absolute;
    bottom: -75px;
    right: 150px;
    top: auto;
    transition: all 0.5s;
    &:hover {
      background-color: #ff2232;
      color: #fff;
    }
  }
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before,
.video-js .vjs-play-control .vjs-icon-placeholder:before,
.vjs-icon-play:before {
  // content: none;
  background: url('/dist/images/play_circle_outline.svg') no-repeat 0 0;
  background-size: 64px;
  font-size: 0;
}
.video-js .vjs-big-play-button {
  border: none;
  background: none !important;
  width: 64px;
  height: 64px;
  @extend %centered;
  &:hover {
    background: none;
  }
}
.video-js .vjs-play-control.vjs-ended .vjs-icon-placeholder:before,
.vjs-icon-replay:before {
  font-size: 14px !important;
  background: none !important;
}
.video-js .vjs-play-control .vjs-icon-placeholder:before {
  background: none !important;
  font-size: 18px !important;
}
