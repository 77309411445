.main_footer {
  float: left;
  width: 100%;
  margin-top: 188px;
  padding-bottom: 60px;
  @include mobile {
    @include rem(margin-top, 100px);
  }
  .footer-top {
    float: left;
    width: 100%;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.26);
    @include mobile {
      @include rem(margin-top, 20px);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .logo-footer {
      float: left;
    }
  }
  .navigation {
    float: right;
    margin-top: 16px;
    ul {
      li {
        float: left;
        margin-right: 20px;
        a {
          color: #fff;
          opacity: 0.4;
          font-size: 16px;
          font-weight: normal;
          text-decoration: none;
          text-transform: uppercase;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .footer-bottom {
    padding-top: 30px;
    float: left;
    width: 100%;
    @include mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    h5 {
      color: #fff;
      opacity: 0.4;
      font-size: 16px;
      font-weight: normal;
      float: left;
    }
    ul {
      float: right;
      li {
        float: left;
        margin-right: 20px;
        @include mobile {
          @include rem(MARGIN-Top, 10px);
        }
        a {
          color: #fff;
          opacity: 0.4;
          font-size: 14px;
          font-weight: normal;
          text-decoration: none;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
