body {
  overflow-x: hidden;
  position: relative;
  background: url('/dist/images/noise.png') repeat #091d16;
  font-family: 'Ubuntu', sans-serif;
}

.logo {
  img {
    float: left;
    width: 282px;
    height: 102px;
    transition: $transition1;
  }
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  padding: 30px 0;
  transition: $transition1;
  .main-header-wrapper {
    display: flex;
    justify-content: space-between;
    .logo {
      z-index: 2;
      a {
        display: inline-block;
      }
      @include mobile {
        width: 30%;
        a {
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
      @include tablet {
        width: 50%;
        a {
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
    .nav-items {
      display: flex;
      align-items: center;
      text-transform: uppercase;
      display: none;
      @include desktop {
        display: flex;
      }
      .schedule-btn {
        @include rem(font-size, 16px);
        font-weight: 700;
        text-decoration: none;
        color: #fff;
        transition: all 0.5s;
        &:hover {
          opacity: 0.7;
        }
      }
      .nav-btn-wrapper {
        @include rem(margin, 0 30px);
        position: relative;
        a {
          @include rem(padding, 15px 25px);
          @include rem(height, 45px);
          @include rem(line-height, 45px);
          @include rem(font-size, 14px);
          font-weight: 700;
          border: 1px solid #ff2232;
          text-align: center;
          text-decoration: none;
          color: #fff;
          z-index: 2;
          &:last-child {
            @include rem(margin-left, 30px);
            background-color: #ff2232;
          }
        }
        &:before {
          content: '';
          position: absolute;
          top: 0;
          @include rem(right, -30px);
          @include rem(width, 70px);
          height: 100%;
          border-right: 1px solid #ffffff;
          z-index: -1;
        }
      }
      .lang-wrapper {
        @include rem(margin-left, 30px);
        a {
          @include rem(font-size, 16px);
          font-weight: 700;
          color: #ffffff;
          opacity: 0.4;
          text-decoration: none;
          transition: all 0.5s;
          &:hover {
            opacity: 0.7;
          }
          &.active {
            position: relative;
            opacity: 1;
            transition: all 0.5s;
            &:hover {
              opacity: 0.7;
            }
            &:before {
              content: '';
              position: absolute;
              bottom: -10px;
              border-bottom: 3px solid #ff2232;
              width: 100%;
              height: 100%;
              left: 0;
            }
          }
          &:last-child {
            @include rem(margin-left, 20px);
          }
        }
      }
      .wallet-btn {
        @include rem(margin-left, 30px);
        a {
          @include rem(padding, 15px 25px);
          @include rem(height, 45px);
          @include rem(line-height, 45px);
          @include rem(font-size, 14px);
          font-weight: 700;
          border: 1px solid #ff2232;
          text-align: center;
          text-decoration: none;
          color: #fff;
          z-index: 2;
          transition: all 0.5s;
          &:hover {
            background-color: #ff2232;
            color: #fff;
          }
        }
      }
    }
    .mobile-hamburger {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center;
      cursor: pointer;

      svg {
        z-index: 9;
      }
      @include desktop {
        display: none;
      }
      .mobile-nav {
        position: fixed;
        visibility: hidden;
        pointer-events: none;
        &.menu-open {
          background-color: #000;
          width: 100%;
          left: 0;
          top: 0;
          visibility: visible;
          pointer-events: all;
          height: 100vh;
          ul {
            @include rem(padding-top, 150px);
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            li {
              @include rem(margin-top, 20px);
              a {
                @include rem(font-size, 16px);
                color: #fff;
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }

  &.fade-in {
    background: url('/dist/images/noise.png') repeat rgba(9, 29, 22, 0.5);
    padding: 10px 0;
    @include mobile {
      background: #000;
    }
    @include tablet {
      background: #000;
    }
    @include tablet-big {
      background: #000;
    }
    .logo {
      img {
        width: 212px;
        height: 72px;
      }
    }
  }
}
